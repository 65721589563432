<template>
  <v-dialog v-model="dialog" width="75%" height="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="dialog = true"
        :title="$t('addChannel')"
        small
      >
        <v-icon small>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ $t('addChannel') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <channel-form v-model="model" @save="onSave" @cancel="close" />
      <v-spacer />
    </v-card>
  </v-dialog>
</template>

<script>
import channelForm from './form.vue'

export default {
  components: {
    channelForm
  },
  props: {
    title: { type: String, default: 'Add Channel' },
    entityId: { type: String, default: null },
    entityType: { type: String, default: null }
  },
  data() {
    return {
      dialog: false,
      model: {}
    }
  },
  mounted() {
    this.model = {
      entityId: this.entityId,
      entityType: this.entityType
    }
  },
  methods: {
    close() {
      this.model = {}
      this.dialog = false
    },
    async onSave(entity) {
      this.close()
      this.$emit('save', entity)
      this.model = {}
    }
  }
}
</script>

<style></style>
