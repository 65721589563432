<template>
  <div>
    <v-card
      flat
      height="90vh"
      width-full
      class="d-flex flex-row"
    >
      <channel-list />

      <v-card cols="1"
        flat
        tile
        style="min-width: 100px; max-width: 100%;"
        class="flex-grow-1 flex-shrink-0 pa-2"
      >
        <router-view class="row3" />
      </v-card>

    </v-card>

  </div>
</template>

<script>
import ChannelList from '@/messaging/components/channel/list/index.vue'

export default {
  components: {
    ChannelList
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
